import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Container, Layout } from 'layout';

import UmbracoImage from 'common/Image/UmbracoImage';
import Button from 'common/Button';
import { navigateToPage } from 'utils/functions';
import replaceHomePageCrumb from 'utils/replaceHomePageCrumb';
import { toBoolean } from 'utils/stringUtils/stringUtils';
import PATHNAME_404 from './constants';

import { NotFoundPageProps } from './models';

import './404.scss';

const NotFoundPage = () => {
  const {
    allPageNotFound: { nodes },
    languageSelector: { defaultLang },
    allSitePage: { nodes: allSiteNodes },
  }: NotFoundPageProps = useStaticQuery(graphql`
    query {
      allPageNotFound {
        nodes {
          langProps {
            urls {
              ...languageUrls
            }
            lang
          }
          notFoundTitle
          notFoundImage {
            fluid {
              originalImg
              srcSet
              base64
            }
            variants {
              alias
              fallbackQuery
              type
              url
            }
            fallbackUrl
          }
          homePageCrumb
          notFoundImageAlt
          notFoundRedirects {
            redirectButtonLink {
              ariaLabel
              link {
                name
                url
              }
              text
              withReverse
            }
            redirectTitle
          }
        }
      }
      languageSelector {
        defaultLang
      }
      allSitePage {
        ...FragmentAllPageSite
      }
    }
  `);

  const [actualLang, setActualLang] = useState(defaultLang);
  const {
    notFoundTitle,
    notFoundImage,
    notFoundImageAlt,
    notFoundRedirects,
    langProps: { urls },
    homePageCrumb,
  } = nodes.filter(({ langProps: { lang } }) => lang === actualLang)[0] || {};
  const [notFoundUrls, setNotFoundUrls] = useState(urls);

  const {
    context: {
      breadcrumb: { crumbs },
    },
  } = allSiteNodes.find((siteNode) => siteNode?.context?.breadcrumb?.location === PATHNAME_404);

  const breadcrumbs = replaceHomePageCrumb(homePageCrumb, crumbs);

  useEffect(() => {
    notFoundUrls.forEach(({ lang, url }, index) => {
      if (url && window.location.pathname.includes(url as string) && index) {
        setActualLang(lang);
      }

      if (url && !(url as string).includes('404')) {
        setNotFoundUrls([...notFoundUrls, { lang, url }]);
      }
    });
  }, [actualLang]);

  const handleNavigation = (pageLink) => navigateToPage(pageLink);

  return (
    <Layout breadcrumbs={breadcrumbs} langProps={{ urls: notFoundUrls, lang: actualLang }}>
      <section className="not-found">
        <div className="not-found__holder">
          <Container className="not-found__content">
            {notFoundImage ? (
              <div className="not-found__image-holder">
                <UmbracoImage image={notFoundImage} alt={notFoundImageAlt} />
              </div>
            ) : null}
            <div className="not-found__title">
              <h1>{notFoundTitle}</h1>
            </div>
            {notFoundRedirects.map((redirectItem, index) => {
              const { ariaLabel, link, text, withReverse } =
                redirectItem.redirectButtonLink[0] || {};

              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className="not-found__redirect-box" key={index}>
                  <h2>{redirectItem.redirectTitle}</h2>
                  <Button
                    ariaLabel={ariaLabel}
                    onClick={() => handleNavigation(link)}
                    isReversed={toBoolean(withReverse)}
                  >
                    {text}
                  </Button>
                </div>
              );
            })}
          </Container>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
